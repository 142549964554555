import React from "react";
import { Row, Col, Container } from "reactstrap";
import leadershipImg from "../../../img/why-incede/leadership.png";

const LeaderShipTeam = () => {
  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={8}
            className="d-flex align-items-center"
          >
            <div>
              <h1 className="text-left heading">Trusted Leadership</h1>
              {/* <h4 className="my-4">
                A team of experienced thought leaders who inspire innovation and
                commitment to customer success
              </h4> */}
              <p className="sub-title">
                Our leadership team consists of seasoned professionals who bring
                a wealth of expertise and dedication to spearheading innovation
                and operational excellence. Their combined experience covers a
                broad spectrum of industries and technologies, positioning our
                firm at the forefront of the consulting industry.
              </p>
              {/* <br />
              <p className="sub-title">
                With decades of management experience, innovation and business
                value are core to the leadership team’s ideals. They are trusted
                to think big and solve the most challenging business problems
                and do so by delivering value quickly and economically. Our
                management team has a shared passion for inspiring and
                empowering our team members to deliver success for and with our
                customers. Our team members are proven and experienced with a
                unique blend of business and technical expertise across a broad
                range of solution areas.
              </p>
              <br />
              <p className="sub-title">
                Our organization's essence is encapsulated by our dedicated
                team, whose commitment consistently surpasses client
                expectations. We are not just industry experts and problem
                solvers, but also partners deeply invested in your success. Our
                holistic solutions are crafted from extensive industry
                knowledge, guiding clients from identifying problems to
                delivering projects, adaptable to any scale of involvement. We
                pride ourselves on being trusted advisors to a diverse client
                base, achieving the highest satisfaction through outstanding
                service and tailored solutions. Our firm's strength lies in our
                continuous pursuit of knowledge, understanding global industry
                trends, which, combined with our consultants' diverse skills,
                defines our identity as a professional services provider.
              </p> */}
              <br />
              <p className="sub-title">
                <h4>
                  <b>Visionary Leadership</b>
                </h4>
                At the core of our leadership philosophy is a commitment to
                pushing the boundaries of technology to deliver exceptional
                results. Our leaders are pioneers in integrating advanced
                solutions into practical business applications, ensuring that
                every project delivers substantial value and drives our clients'
                success. They are not just strategists but hands-on experts who
                actively engage in shaping the future of technology.
              </p>
              <br />
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={4}
            className="mt-4 mt-lg-0 d-flex align-items-center"
          >
            <div className="image-section">
              <img src={leadershipImg} alt="leadership team" />
            </div>
          </Col>
          <Col xs={12}>
            <p className="sub-title">
              <h4>
                <b>Innovation and Expertise</b>
              </h4>
              Our leaders are recognized for their innovative approaches and
              deep understanding of how technology can solve complex business
              challenges. They foster a culture of innovation where creative
              ideas and solutions flourish. This environment encourages
              continuous improvement and adaptation to new technologies and
              market demands.
            </p>
            <br />
            <p className="sub-title">
              <h4>
                <b>Commitment to Excellence</b>
              </h4>
              Under their guidance, Incede is dedicated to maintaining the
              highest standards of service delivery. Our leaders uphold the
              principles of integrity and transparency, ensuring that all client
              engagements are handled with the utmost professionalism and
              ethical considerations.
            </p>
            <br />
            <p className="sub-title">
              <h4>
                <b>Engagement and Collaboration</b>
              </h4>
              They believe in a collaborative approach, engaging with clients,
              team members, and industry partners to leverage collective
              knowledge and insights. This collaboration is crucial in
              developing tailored solutions that meet specific business needs
              and exceed expectations.
            </p>
            <br />
            <p className="sub-title">
              Our leadership team is pivotal in ensuring that Incede remains a
              trusted partner for businesses looking to navigate the
              complexities of the modern digital landscape. Explore more about
              our leadership's vision and the innovative pathways they are
              crafting by connecting with us. Together, let's drive
              transformative change and achieve sustainable growth.
            </p>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default LeaderShipTeam;
